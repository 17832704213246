@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack-history {
    font-size: 8.5px;
    line-height: 10px;
    height: 100%;
    color: $primary;

    @include tablet {
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
    }

    &>span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $secondary;
    }

    &>table {
        width: 100%;
        font-size: 8px;
        line-height: 10px;

        @include tablet {
            font-size: 10px;
            line-height: 13px;
        }
        
        &>thead {
            color: fade-out($color: $primary, $amount: 0.4);

            font-size: 8px;
            line-height: 10px;

            @include tablet {
                font-size: 14px;
                line-height: 16px;
                font-weight: 600;
            }

            th {
                padding-bottom: 20px;

                @include tablet {
                    padding-bottom: 30px;
                }
            }
        }

        &>tbody {
            color: $primary;
            text-align: center;

            td {
                padding-bottom: 10px;

                @include tablet {
                    padding-bottom: 20px;
                }
            }

            .red {
                color: $red;
            }

            .black {
                color: $black;
            }

            .red>i, .black>i {
                display: inline-block;
                width: 8px;
                height: 8px;
                background: currentColor;
                border-radius: 50%;
            }

            .joker {
                color: #521698;

                >i {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background: currentColor;
                    border-radius: 50%;
                    margin-left: 2px;
                }
            }

            svg {
                width: 8px;
                height: 8px;
                margin-left: 2px;

                @include tablet {
                    width: 10px;
                    height: 10px;
                }
            }

            span {
                min-width: 8px;
                min-height: 8px;
                margin-left: 5px;
                display: inline-flex;
                flex-direction: row;
                text-align: center;
                justify-content: center;
                align-items: center;

                @include tablet {
                    min-width: 10px;
                    min-height: 10px;
                    margin-left: 10px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

@font-face {
    font-family: 'spartan';
    src: local('spartan'),
        url('./assets/fonts/spartanmb-semibold-webfont.woff2') format('woff2'),
        url('./assets/fonts/spartanmb-semibold-webfont.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}

.redblack-card {
    display: inline-block;
    width: 150px;
    height: 210px;
    perspective: 1450px;

    @include tablet {
        width: 200px;
        height: 280px;
    }

    &,
    & * {
        font-family: 'spartan';
    }

    &>.redblack-card-inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &>article {
            transition: all .5s .5s;
            transition-property: transform, filter, box-shadow;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            border-radius: 16px;
            overflow: hidden;

            &.redblack-card-back {
                background-size: cover;
                background-repeat: no-repeat;
                transform: translateX(0%) rotateY(0deg);
                transform-origin: 130% center;
                box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
                filter: brightness(1);
            }

            &.redblack-card-front {
                transform: translateX(160%) rotateY(-180deg);
                transform-origin: -30% center;
                box-shadow: 130px 30px 40px -20px rgba(0, 0, 0, 0);
                filter: brightness(3);

                &>main {
                    display: flex;
                    flex: 1;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;

                    &>svg {
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                        object-fit: cover;

                        @include tablet {
                            width: 96px;
                            height: 96px;
                        }
                    }
                }

                &>header,
                &>footer {
                    position: absolute;
                    width: 24px;
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    &>h3 {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0px;

                        @include tablet {
                            font-size: 32px;
                            line-height: 32px;
                        }
                    }

                    &>svg {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin: 0 auto;
                        object-fit: cover;

                        @include tablet {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &>header {
                    top: 10px;
                    left: 10px;

                    @include tablet {
                        top: 24px;
                        left: 24px;
                    }
                }

                &>footer {
                    bottom: 10px;
                    right: 10px;
                    transform: rotate(180deg);

                    @include tablet {
                        bottom: 24px;
                        right: 24px;
                    }
                }

                &.redblack-card-front-joker {
                    background-image: url("./assets/joker.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }

        &.redblack-card-inner-dark>article {
            background-color: $primary;

            &.redblack-card-back {
                background-image: url("./assets/back-dark.svg");
            }

            &.redblack-card-front {
                &.redblack-card-front-red {
                    color: $white;
                    background: $red;
                }

                &.redblack-card-front-black {
                    color: $white;
                    background: $black;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%274%27%20height%3D%274%27%20viewBox%3D%270%200%204%204%27%3E%3Cpath%20fill%3D%27white%27%20fill-opacity%3D%270.06%27%20d%3D%27M1%203h1v1H1V3zm2-2h1v1H3V1z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
                background-size: 6px 6px;
            }
        }

        &.redblack-card-inner-light>article {
            background-color: $white;

            &.redblack-card-back {
                background-image: url("./assets/back-light.svg");
            }

            &.redblack-card-front {
                &.redblack-card-front-red {
                    color: $red;
                }

                &.redblack-card-front-black {
                    color: $black;
                }
            }
        }

        &.redblack-card-inner-revealed {
            &>.redblack-card-back {
                transition-delay: 0s;
                transform: translateX(-160%) rotateY(180deg);
                box-shadow: 80px 10px 20px -40px transparent;
                filter: brightness(0);
            }

            &>.redblack-card-front {
                transition-delay: 0s;
                transform: translateX(0%) rotateY(0deg);
                box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
                filter: brightness(1);
            }
        }
    }
}

button.redblack-card {
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack-instructions {
    font-size: 8.5px;
    line-height: 10px;
    height: 100%;
    color: $primary;

    @include tablet {
        font-size: 14px;
        line-height: 16px;
    }

    &>ol {
        padding: 0 0 0 10px;
        margin: 10px 0;

        @include tablet {
            padding: 0 0 0 24px;
            margin: 16px 0;
        }

        &>li {
            margin-top: 10px;

            @include tablet {
                margin-top: 20px;
            }
        }
    }

    &>h4 {
        font-weight: normal;
    }
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack-menu {
    width: 245px;
    height: 178px;
    transition: transform 0.3s;

    @include tablet {
        width: 350px;
        height: 240px;
    }

    .redblack-modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: fade-out($color: black, $amount: 0.5);
        z-index: 10;
    }

    &>header {
        position: absolute;
        width: 245px;
        height: 120px;
        border-radius: 15px;
        box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
        transform: translateY(-50%);
        z-index: 13;
        background: $white;
        color: $primary;

        @include tablet {
            width: 350px;
            height: 160px;
        }

        &>svg {
            height: 24px;
            width: 100%;
            object-fit: "cover";
            position: absolute;
            bottom: 18px;
            left: 0;
            right: 0;

            @include tablet {
                height: 40px;
                bottom: 24px;
            }
        }

        // &>div {
        //     font-size: 28px;
        //     line-height: 34px;
        //     bottom: 15px;
        //     font-style: italic;
        //     font-weight: 700;
        //     color: $base;
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     text-align: center;

        //     @include tablet {
        //         bottom: 25px;
        //     }

        //     &>span:first-child {
        //         margin: 0 10px;
        //         color: $red;
        //     }

        //     &>span:last-child {
        //         margin: 0 10px;
        //         color: $primary;
        //     }
        // }
    }

    &>footer {
        &>* {
            padding: 0;
            margin: 0;
            position: absolute;
            width: 245px;
            height: 343px;
            border-radius: 15px;
            box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
            transform: translateY(-50%);
            z-index: 11;
            background: $white;
            color: $primary;
            text-align: center;
            border: none;
            transition: all 0.3s;
            font-weight: bold;

            @include tablet {
                width: 350px;
                height: 490px;
            }

            &:nth-child(1) {
                top: 6px;

                @include tablet {
                    top: -4px;
                }
            }

            &:nth-child(2) {
                top: -23px;

                @include tablet {
                    top: -44px;
                }
            }

            &>span {
                position: absolute;
                bottom: 0;
                font-size: 10px;
                line-height: 28px;
                text-align: center;
                right: 0;
                left: 0;
                margin: 0;

                @include tablet {
                    font-size: 14px;
                    line-height: 40px;
                }
            }
        }

        &>button {
            cursor: pointer;
            transition: all 0.3s;
            color: fade-out($color: $primary, $amount: 0.4);

            &:hover:not(:disabled) {
                color: $primary;
            }

            &:disabled {
                cursor: default;
            }
        }
    }

    &>main {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 12;

        .redblack-menu-modal {
            position: absolute;
            top: 0;
            left: 0;
            top: calc(var(--screen-height, 100vh) * -0.4);

            width: 245px;
            height: 343px;
            background: $white;
            color: $primary;
            transform-origin: center;
            transform: translate(-50%, -50%);
            border-radius: 15px;
            box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
            cursor: pointer;
            transition: all 0.3s;

            @include tablet {
                top: calc(var(--screen-height, 100vh) * -0.4);
                width: 350px;
                height: 490px;
            }

            &:nth-child(3) {
                top: calc(var(--screen-height, 100vh) / -2 + -22px);
                z-index: 1;

                @include tablet {
                    top: calc(var(--screen-height, 100vh) / -2 + -44px);
                }
            }

            &:nth-child(2) {
                top: calc(var(--screen-height, 100vh) / -2 + -52px);
                z-index: 2;

                @include tablet {
                    top: calc(var(--screen-height, 100vh) / -2 + -84px);
                }
            }

            &:nth-child(1) {
                top: calc(var(--screen-height, 100vh) / -2 + -82px);
                z-index: 3;

                @include tablet {
                    top: calc(var(--screen-height, 100vh) / -2 + -124px);
                }
            }

            &>h2 {
                position: absolute;
                bottom: 0;
                font-size: 10px;
                line-height: 28px;
                text-align: center;
                right: 0;
                left: 0;
                color: fade-out($color: $primary, $amount: 0.4);
                margin: 0;
                font-weight: normal;
                transition: all 0.3s;

                @include tablet {
                    font-size: 14px;
                    line-height: 40px;
                }
            }

            &>article {
                position: absolute;
                left: 0;
                right: 0;
                top: 20px;
                padding: 0 20px;
                bottom: 35px;
                overflow: auto;
                overflow-y: overlay;

                @include tablet {
                    top: 30px;
                    padding: 0 30px;
                    bottom: 45px;
                }
            }

            &.redblack-menu-modal-disabled {
                cursor: default;
            }

            &:not(.redblack-menu-modal-active):not(.redblack-menu-modal-disabled):hover>h2 {
                color: $primary;
            }

            &.redblack-menu-modal-active {
                transform: translate(-50%, -50%);
                cursor: default;
                top: 0 !important;
                margin: 0 !important;
            }
        }
    }

    &.redblack-menu-active {
        .redblack-menu-modal {
            --margin-offset: 0px;

            &:nth-child(1) {
                margin-top: -22px;
                margin-top: calc(-22px + var(--margin-offset));

                @include tablet {
                    margin-top: -30px;
                    margin-top: calc(-30px + var(--margin-offset));
                }
            }

            &:nth-child(2) {
                margin-top: -45px;
                margin-top: calc(-45px + var(--margin-offset));

                @include tablet {
                    margin-top: -60px;
                    margin-top: calc(-60px + var(--margin-offset));
                }
            }

            &:nth-child(3) {
                margin-top: -68px;
                margin-top: calc(-68px + var(--margin-offset));

                @include tablet {
                    margin-top: -90px;
                    margin-top: calc(-90px + var(--margin-offset));
                }
            }

            &.redblack-menu-modal-active~.redblack-menu-modal {
                --margin-offset: -7px;

                @include tablet {
                    --margin-offset: -10px;
                }
            }

            &:not(.redblack-menu-modal-active) {
                pointer-events: none;
                cursor: none;
            }
        }

        &>footer>* {
            &:nth-child(1) {
                top: -90px;

                @include tablet {
                    top: -134px;
                }
            }

            &:nth-child(2) {
                top: -98px;

                @include tablet {
                    top: -144px;
                }
            }

            pointer-events: none;
            cursor: none;
        }
    }
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.redblack-splash {
    background-color: $base;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2712%27%20height%3D%2716%27%20viewBox%3D%270%200%2012%2016%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M4%20.99C4%20.445%204.444%200%205%200c.552%200%201%20.45%201%20.99v4.02C6%205.555%205.556%206%205%206c-.552%200-1-.45-1-.99V.99zm6%208c0-.546.444-.99%201-.99.552%200%201%20.45%201%20.99v4.02c0%20.546-.444.99-1%20.99-.552%200-1-.45-1-.99V8.99z%27%20fill%3D%27white%27%20fill-opacity%3D%270.06%27%20fill-rule%3D%27evenodd%27%2F%3E%3C%2Fsvg%3E");
    background-repeat: repeat;
    background-position: center center;

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    cursor: pointer;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    &>i {
        background: url("./assets/splash.png");
        background-size: cover;
        width: 400px;
        height: 400px;

        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: -30px;

        @include tablet {
            width: 700px;
            height: 700px;
        }

        @include large {
            width: 1000px;
            height: 1000px;
        }
    }

    &>span {
        color: $black;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;


        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 120px;

        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        @include tablet {
            margin-top: 200px;
        }

        @include large {
            margin-top: 300px;
        }
    }

    &>footer {
        font-size: 12px;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        padding: 20px;
    }
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack-bet-placement {
    width: 352px;
    height: 280px;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
    z-index: 5;
    background: $white;
    color: $primary;
    overflow: hidden;
    padding: 24px 60px;
    box-sizing: border-box;
    transition: transform 0.3s;
    transform: scale(0.7);
    transform-origin: bottom center;

    @include tablet {
        transform: scale(1);
    }

    &>.redblack-bet-placement-status {
        margin-bottom: 2px;

        &>ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 54px;
            box-sizing: border-box;

            &>li {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: $base;
                transition: all 0.3s;

                &.redblack-bet-placement-status-black {
                    background: $black;
                }

                &.redblack-bet-placement-status-red {
                    background: $red;
                }
            }
        }
    }

    &>.redblack-bet-placement-amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 168px;
        width: 100%;
        z-index: -1;
        position: relative;

        &>div {
            width: 100%;
            position: relative;

            &::before {
                display: block;
                content: "Bet";
                position: absolute;
                top: 50%;
                left: 16px;
                pointer-events: none;
                transform: translate(0, -50%);
                color: $secondary;
                font-size: 16px;
                font-weight: 700;
            }

            .picker-item {
                font-size: 18px;
                font-weight: 700;
                color: $black;
            }

            .picker-inner {
                -webkit-mask-box-image: none;

                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 64px;
                    background: linear-gradient(180deg, #FFFFFF 11.9%, rgba(255, 255, 255, 0.8) 100%);
                    pointer-events: none;
                    z-index: 1;
                }

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 64px;
                    background: linear-gradient(0deg, #FFFFFF 11.9%, rgba(255, 255, 255, 0.8) 100%);
                    pointer-events: none;
                    z-index: 1;
                }
            }

            .picker-highlight:after, .picker-highlight:before {
                // transform: none;
                // height: 2px;
                // background: $secondary;
                // opacity: 0.1;
                display: none;
            }

            .picker-highlight {
                background: fade-out($color: $secondary, $amount: 0.9);
                border-radius: 4px;
                border: 2px solid fade-out($color: $secondary, $amount: 0.9);
                z-index: -1;
            }
        }

        &>h5 {
            width: 100%;
            text-align: center;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: $primary;

            &.redblack-bet-placement-status-message-black {
                color: $black;
            }

            &.redblack-bet-placement-status-message-red {
                color: $red;
            }
        }
    }

    &>.redblack-bet-placement-buttons {
        display: flex;
        flex-direction: row;
        height: 48px;

        &>button {
            flex: 1;
            margin-left: 24px;
            color: $white;
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border: none;
            border-radius: 4px;
            text-transform: uppercase;
            cursor: pointer;
            background: $primary;
            transition: all 0.3s;

            &:disabled {
                cursor: default;
            }

            &:not(:disabled):hover {
                transform: translateY(-2px);
                // box-shadow: 0px 1px 4px #00000040;
            }

            &:first-child {
                margin-left: 0;
            }

            &.redblack-bet-placement-button-red {
                background: $red;
                font-size: 18px;
            }

            &.redblack-bet-placement-button-black {
                background: $black;
                font-size: 18px;
            }
        }
    }
}

.redblack-menu-active~.redblack-bet-placement {
    transform: scale(0.7) translateY(195px);

    @include tablet {
        transform: scale(1) translateY(195px);
    }
}
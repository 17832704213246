@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include tablet {
        padding-bottom: 30px;
    }

    &>table {
        width: 100%;
        font-size: 10px;
        line-height: 13px;
        padding: 0;

        @include tablet {
            font-size: 12px;
            line-height: 15px;
            padding: 10px;
        }

        th {
            text-align: left;
            color: fade-out($color: $primary, $amount: 0.4);
            font-weight: normal;
            padding-bottom: 10px;

            @include tablet {
                padding-bottom: 20px;
            }
        }

        td {
            text-align: right;
            color: $primary;
            font-weight: normal;
            padding-bottom: 10px;

            @include tablet {
                padding-bottom: 20px;
            }
        }
    }

    &>button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 210px;
        height: 40px;
        background: $primary;
        color: $white;
        box-shadow: 0px 2px 4px fade-out($color: black, $amount: 0.5);
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;

        @include tablet {
            height: 70px;
        }
    }

    &>button:hover {
        transform: translateY(-2px);
    }
}
@import "../../themes/redblack.scss";
@import "../../themes/dimensions.scss";

.redblack {
    background-color: #396126;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2712%27%20height%3D%2716%27%20viewBox%3D%270%200%2012%2016%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M4%20.99C4%20.445%204.444%200%205%200c.552%200%201%20.45%201%20.99v4.02C6%205.555%205.556%206%205%206c-.552%200-1-.45-1-.99V.99zm6%208c0-.546.444-.99%201-.99.552%200%201%20.45%201%20.99v4.02c0%20.546-.444.99-1%20.99-.552%200-1-.45-1-.99V8.99z%27%20fill%3D%27white%27%20fill-opacity%3D%270.06%27%20fill-rule%3D%27evenodd%27%2F%3E%3C%2Fsvg%3E");
    background-repeat: repeat;
    background-position: center center;
    max-width: 100vw;
    max-height: var(--screen-height, 100vh);
    overflow: hidden;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>div {
        width: 100%;
        margin: 0 auto;
        height: 100%;

        &>.watermark {
            position: absolute;
            width: 300px;
            height: 300px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url("./assets/logo-optimized.png");
            background-size: cover;
            opacity: 0.23;
            z-index: 0;

            @include tablet {
                width: 400px;
                height: 400px;
            }

            @include xlarge {
                transform: translate(245px, -50%);
            }
        }

        &>aside.themes {
            display: none;

            @include xlarge {
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translate(-245px, -50%);
                margin-top: -20px;
                flex-direction: row;
                display: flex;
            }

            &>* {
                margin: 20px;

                .selected {
                    z-index: 1;
                }
            }

            .selected {
                &>* {}
            }

            &>h3 {
                position: absolute;
                left: 0;
                right: 0;
                bottom: -40px;
                text-align: center;
                font-size: 12px;
                font-weight: normal;
                color: $white;

                // @include tablet {
                //     bottom: -48px;
                //     font-size: 16px;
                // }
            }
        }

        &>div {
            display: flex;
            flex-direction: column;
            height: 100%;
            align-items: center;
            justify-content: space-between;

            &>main {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 190px;
                height: 250px;
                transform: translate(-50%, -50%);
                z-index: 1;
                margin-top: -9px;

                @include tablet {
                    margin-top: -20px;
                }

                border: 4px solid $table-border;
                border-radius: 5px;

                &>* {
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    transform-origin: center;
                    z-index: 5;
                    transition: z-index 1s step-end, transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
                    transform-style: preserve-3d;
                    transform: translateZ(0);
                    backface-visibility: hidden;
                    perspective: 1000;

                    &:first-child {
                        transform: rotate(3deg);
                    }
                }

                @include tablet {
                    width: 250px;
                    height: 330px;

                    &>* {
                        top: 21px;
                        left: 21px;
                    }
                }

                main::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    background: currentColor;
                    opacity: 0;
                    transition: opacity 0.8s;
                }

                &>.redblack-result {
                    z-index: 6;
                    transition: z-index 1s step-start, transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);

                    &.redblack-result-1,
                    &.redblack-result-2,
                    &.redblack-result-3,
                    &.redblack-result-4 {
                        main::after {
                            opacity: 1;
                        }

                        article {
                            box-shadow: 0px -2px 4px fade-out($color: black, $amount: 0.5) !important;
                        }
                    }

                    &.redblack-result-1 {
                        transform: translate(130px, -88.5px) translateZ(0) scale(0.25);
                    }

                    &.redblack-result-2 {
                        transform: translate(130px, -31px) translateZ(0) scale(0.25);
                    }

                    &.redblack-result-3 {
                        transform: translate(130px, 26.5px) translateZ(0) scale(0.25);
                    }

                    &.redblack-result-4 {
                        transform: translate(130px, 84px) translateZ(0) scale(0.25);
                    }

                    @include tablet {
                        main::after {
                            opacity: 0 !important;
                        }

                        &.redblack-result-1 {
                            transform: translate(350px, -160px) translateZ(0) rotate(193deg);
                        }

                        &.redblack-result-2 {
                            transform: translate(320px, -70px) translateZ(0) rotate(172deg);
                        }

                        &.redblack-result-3 {
                            transform: translate(320px, 40px) translateZ(0) rotate(184deg);
                        }

                        &.redblack-result-4 {
                            transform: translate(330px, 160px) translateZ(0) rotate(165deg);
                        }
                    }

                    @include large {
                        &.redblack-result-1 {
                            transform: translate(480px, -160px) translateZ(0) rotate(193deg);
                        }

                        &.redblack-result-2 {
                            transform: translate(530px, -75px) translateZ(0) rotate(172deg);
                        }

                        &.redblack-result-3 {
                            transform: translate(500px, 30px) translateZ(0) rotate(184deg);
                        }

                        &.redblack-result-4 {
                            transform: translate(490px, 160px) translateZ(0) rotate(165deg);
                        }
                    }
                }
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent radial-gradient(circle at center, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
        opacity: 0.3;
        pointer-events: none;
    }

    &::after {
        content: "";
        position: absolute;
        border: 4px solid $table-border;
        border-radius: 5px;
        pointer-events: none;
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;

        @include tablet {
            left: 50px;
            right: 50px;
            top: 50px;
            bottom: 50px;
        }
    }

    &>footer {
        font-size: 12px;
        position: absolute;
        left: 50px;
        bottom: 0;
        text-align: left;
        padding: 20px;
        z-index: 0;
        color: $base;
        display: none;

        @include large {
            display: block;
        }
    }
}